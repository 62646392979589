<template>
    <KCourse loader-class="MBcont" course-id="7" title="Детская голова" :items="items">
        <div class="MBtextcont">
            <h3>3.1 Дорабатываем лицо</h3>
        </div>
        <VideoView video-id="f3fb92434ab84ebe8b0b700abde35698"/>

        <div class="MBtextcont">
            <h3>3.2 Вставляем перемычку</h3>
        </div>
        <VideoView video-id="79695436860848928761b1a656034e60"/>

        <div class="MBtextcont">
            <h3>3.3 Делаем шарнироприемники</h3>
        </div>
        <VideoView video-id="be8c47e078644e7fbb9bbf22e5237e3e"/>

        <div class="MBtextcont">
            <h3>3.4 Лепим ухо</h3>
        </div>
        <VideoView video-id="106faf85feb949128b778237c021365e"/>

        <div class="MBtextcont">
            <h3>3.5 Собираем</h3>
        </div>
        <VideoView video-id="1d77777232fb46e7bbe00cd9be4e9cfd"/>


    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
            return {
                items: [
                    { title: "Делаем заготовки", url:"/mycourses/childhead/1"},
                    { title: "Лепим лицо", url:"/mycourses/childhead/2"},
                    { title: "Дорабатываем детали", url:"/mycourses/childhead/3"}
                ],
            }
        }
    }
</script>